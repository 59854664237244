import React, {useState, useEffect} from "react";
import useFetch from "use-http";
import DayDataFetcher from "./DayDataFetcher.jsx";
import ErrorMessage from "./ErrorMessage.jsx";
import {AccountContextProvider} from "./AccountContext.jsx";

function LoginController() {
  const {loading, error, response, data: accountData} = useFetch(
    '/api/v1/account', []);
  console.debug("accountData:",accountData);
  
  useEffect(() => {
    try {
      document.title = `Income Report: ${accountData.Account.name}`;
    }
    catch (e) {
      return;
    }
  },[accountData]);

  
  if (loading || accountData === null) {
    return (
      <div className="m-4 text-center">
        <div className="spinner-border my-4" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  
  if (error && response.status !== 401) {
    return <ErrorMessage message={error}/>;
  }

  if (response.status !== 401 && response.status !== 200) {
    return <ErrorMessage
             message={`Server returned unexpected response code ${response.status}`}/>;
  }
  
  const isLoggedIn = (response.status === 200);

  if (!isLoggedIn) {
    return (
      <div className="m-3">
        <p>You are not logged in, or you have not granted this application access to your account.</p>
        <a href="/login" className="btn btn-primary">Log In</a>
      </div>
    );
  }

  const accounts = (Array.isArray(accountData.Account)
                    ? accountData.Account
                    : [accountData.Account]);
  
  return (
    <>
      <div className="row m-3 align-middle">
        <div className="col">
          <h2>Income Report: {accounts[0].name}</h2> 
        </div>
        <div className="col-auto">
          <a href="/logout" className="btn btn-primary">Log Out</a>
        </div>
      </div>
      <AccountContextProvider value={accounts[0]}>
        <DayDataFetcher accountID={accounts[0].accountID}/>
      </AccountContextProvider>
    </>
  );
}

export default LoginController;
