import React from "react";
import Dinero from "dinero.js";
import ErrorMessage from "./ErrorMessage.jsx";
import {useAccount} from "./AccountContext.jsx";
import "./print.css";

export default function IncomeReport({
  invalidSales,
  validSalesDetailLines,
  reportGenerated,
  reportPeriodStart,
  reportPeriodEnd
}) {
  console.debug("IncomeReport got invalidSales:", invalidSales,
                "validSalesDetailLines:", validSalesDetailLines);
  const topLevelCategories =
        Array.from((new Set(validSalesDetailLines.map(line => line.topLevelCategory))));
  const tenderTypes = ["card", "cash", "check", "ecom"];
  
  const validSalesDetailLinesByCategoryTender = Object.fromEntries(
    topLevelCategories.map(
      topLevelCategory =>
        [topLevelCategory, Object.fromEntries(
          tenderTypes.map(
            tenderType =>
              [tenderType, validSalesDetailLines.filter(
                line => line.topLevelCategory === topLevelCategory &&
                  line.tenderType === tenderType
              )
              ]
          )
        )
        ]
    )
  );

  const zeroDollars = Dinero({amount: 0, currency: "USD"});

  const summaryLines = Object.entries(validSalesDetailLinesByCategoryTender)
        .map(([topLevelCategory, tenders]) => ({
          topLevelCategory,
          cardTotal: tenders.card.map(line => line.total)
            .reduce((a,b) => a.add(b), zeroDollars),
          cashTotal: tenders.cash.map(line => line.total)
            .reduce((a,b) => a.add(b), zeroDollars),
          checkTotal: tenders.check.map(line => line.total)
            .reduce((a,b) => a.add(b), zeroDollars),
          ecomTotal: tenders.ecom.map(line => line.total)
            .reduce((a,b) => a.add(b), zeroDollars)
        }))
        .sort((a,b) => a.topLevelCategory > b.topLevelCategory ? 1 : -1);

  const sortedDetailReports =
        Object.entries(validSalesDetailLinesByCategoryTender)
        .sort((a,b) => a[0] > b[0] ? 1 : -1) // sort by topLevelCategory
        .map(([topLevelCategory, linesByTender]) => (
          Object.entries(linesByTender)
            .sort((a,b) => a[0] > b[0] ? 1 : -1) // sort by tenderType
            .map(([tenderType, detailLines]) => (
              {topLevelCategory,
               tenderType,
               detailLines}
            ))
        ))
        .reduce((a,b) => a.concat(b), []);
  
  return (
    <>
      {reportGenerated < reportPeriodStart
       ? <div className="mb-3">
           <p className="text-danger">
             Selected reporting period is in the future.
           </p>
         </div>
       : null}
      { reportGenerated >= reportPeriodStart &&
        reportGenerated < reportPeriodEnd
       ? <div className="mb-3">
           <p className="text-danger">
             Report generated during the selected reporting
             period. Sales will be missing from this report if they
             occur after the report generation time.
           </p>
         </div>
       : null}
      <SummaryReport
        summaryLines={summaryLines}
        reportGenerated={reportGenerated}
        reportPeriodStart={reportPeriodStart}
        reportPeriodEnd={reportPeriodEnd}
      />
      {sortedDetailReports.map(
        sortedDetailReport =>
          <LineItemDetailReport
            topLevelCategory={sortedDetailReport.topLevelCategory}
            tenderType={sortedDetailReport.tenderType}
            lineItems={sortedDetailReport.detailLines}
            reportGenerated={reportGenerated}
            reportPeriodStart={reportPeriodStart}
            reportPeriodEnd={reportPeriodEnd}
            key={sortedDetailReport.topLevelCategory + sortedDetailReport.tenderType}
          />
      )}
      <InvalidSales invalidSales={invalidSales}/>
    </>
  );
}

function SummaryReport({
  summaryLines,
  reportGenerated,
  reportPeriodStart,
  reportPeriodEnd
}) {
  const zeroDollars = Dinero({amount: 0, currency: "USD"});
  
  return (
    <div className="mb-4 summary-report">
      <h2>Summary Report</h2>
      <dl className="row">
        <dt className="col-sm-3">Report Generated</dt>
        <dd className="col-sm-9">{reportGenerated.toISO()}</dd>
        <dt className="col-sm-3">Report Period Start</dt>
        <dd className="col-sm-9">{reportPeriodStart.toISO()}</dd>
        <dt className="col-sm-3">Report Period End</dt>
        <dd className="col-sm-9">{reportPeriodEnd.toISO()}</dd>
      </dl>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Top Level Category</th>
            <th scope="col" className="text-end">Card Total</th>
            <th scope="col" className="text-end">eCom Total</th>
            <th scope="col" className="text-end">Card + eCom Total</th>
            <th scope="col" className="text-end">Cash Total</th>
            <th scope="col" className="text-end">Check Total</th>
            <th scope="col" className="text-end">Cash + Check Total</th>
            <th scope="col" className="text-end">All Tenders</th>
          </tr>
        </thead>
        <tbody>
          {summaryLines.length === 0
           ? <tr>
               <td colSpan="5" className="text-center">
                 No sale income for the selected reporting period.
               </td>
             </tr>
           : summaryLines.map(summaryLine => (
             <tr key={summaryLine.topLevelCategory}>
               <td>{summaryLine.topLevelCategory}</td>
               <td className="text-end">{summaryLine.cardTotal.toFormat("$0,0.00")}</td>
               <td className="text-end">{summaryLine.ecomTotal.toFormat("$0,0.00")}</td>
               <td className="text-end">{summaryLine.cardTotal.add(summaryLine.ecomTotal)
                                         .toFormat("$0,0.00")}</td>
               <td className="text-end">{summaryLine.cashTotal.toFormat("$0,0.00")}</td>
               <td className="text-end">{summaryLine.checkTotal.toFormat("$0,0.00")}</td>
               <td className="text-end">{summaryLine.cashTotal.add(summaryLine.checkTotal)
                                         .toFormat("$0,0.00")}</td>
               <td className="text-end">
                 {summaryLine.cardTotal
                  .add(summaryLine.ecomTotal)
                  .add(summaryLine.cashTotal)
                  .add(summaryLine.checkTotal)
                  .toFormat("$0,0.00")}
               </td>
             </tr>
           ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="row">All Categories</th>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.cardTotal)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}
            </td>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.ecomTotal)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}
            </td>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.cardTotal
                    .add(summaryLine.ecomTotal))
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}
            </td>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.cashTotal)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}            </td>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.checkTotal)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}
            </td>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.cashTotal
                    .add(summaryLine.checkTotal))
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}
            </td>
            <td className="text-end">
              {summaryLines
               .map(summaryLine => summaryLine.cashTotal
                    .add(summaryLine.checkTotal)
                    .add(summaryLine.cardTotal)
                    .add(summaryLine.ecomTotal))
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

function LineItemDetailReport({
  topLevelCategory,
  tenderType,
  lineItems,
  reportGenerated,
  reportPeriodStart,
  reportPeriodEnd
}) {
  const zeroDollars = Dinero({amount: 0, currency: "USD"});
  return (
    <div className={`mb-4 line-item-detail-report line-item-detail-report-${tenderType}`}>
      <h3>Line Item Detail Report</h3>
      <dl className="row">
        <dt className="col-sm-3">Top Level Category</dt>
        <dd className="col-sm-9">{topLevelCategory}</dd>
        <dt className="col-sm-3">Payment Code</dt>
        <dd className="col-sm-9">{tenderType}</dd>
        <dt className="col-sm-3">Report Generated</dt>
        <dd className="col-sm-9">{reportGenerated.toISO()}</dd>
        <dt className="col-sm-3">Report Period Start</dt>
        <dd className="col-sm-9">{reportPeriodStart.toISO()}</dd>
        <dt className="col-sm-3">Report Period End</dt>
        <dd className="col-sm-9">{reportPeriodEnd.toISO()}</dd>
      </dl>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Sale ID</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Employee Last</th>
            <th scope="col">Category</th>
            <th scope="col" className="text-end">Subtotal</th>
            <th scope="col" className="text-end">Tax 1</th>
            <th scope="col" className="text-end">Tax 2</th>
            <th scope="col" className="text-end">Total</th>
            <th scope="col">Payment Code</th>
            {tenderType === "card"
             ? <>
                 <th scope="col">Card Type</th>
                 <th scope="col">Card Last 4</th>
               </>
             : null}
          </tr>
        </thead>
        <tbody>
          {lineItems.length === 0
           ? <tr>
               <td colSpan={tenderType === "card" ? 11 : 9}
                   className="text-center">
                 No sales for this report period, top level category,
                 and payment code.
               </td>
             </tr>
           : lineItems.map(lineItem => (
             <tr key={lineItem.saleID + lineItem.saleLineID}>
               <td>{lineItem.saleID}</td>
               <td>{lineItem.saleDateTime.toISO()}</td>
               <td>{lineItem.employeeLast}</td>
               <td>{lineItem.topLevelCategory}</td>
               <td className="text-end">{lineItem.subtotal.toFormat("$0,0.00")}</td>
               <td className="text-end">{lineItem.tax1.toFormat("$0,0.00")}</td>
               <td className="text-end">{lineItem.tax2.toFormat("$0,0.00")}</td>
               <td className="text-end">{lineItem.total.toFormat("$0,0.00")}</td>
               <td>{lineItem.tenderType}</td>
               {tenderType === "card"
                ? <>
                    <td>{lineItem.cardBrand}</td>
                    <td>{lineItem.cardLastFour}</td>
                  </>
                : null}
             </tr>
           ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="row">Total:</th>
            <td colSpan="3"></td>
            <td className="text-end">
              {lineItems
               .map(lineItem => lineItem.subtotal)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")
              }
            </td>
            <td className="text-end">
              {lineItems
               .map(lineItem => lineItem.tax1)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")
              }
            </td>
            <td className="text-end">
              {lineItems
               .map(lineItem => lineItem.tax2)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")
              }
            </td>
            <td className="text-end">
              {lineItems
               .map(lineItem => lineItem.total)
               .reduce((a,b) => a.add(b), zeroDollars)
               .toFormat("$0,0.00")
              }
            </td>
            <td colSpan={tenderType === "card" ? 3 : 1}/>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

function InvalidSales({invalidSales}) {
  const accountInfo = useAccount();
  
  return (
    <div className="mb-4 excluded-sales-report">
      <h3>Excluded Sales</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Sale ID</th>
            <th scope="col">Excluded Because</th>
          </tr>
        </thead>
        <tbody>
          {invalidSales.length === 0
           ? <tr>
               <td colSpan="2" className="text-center">
                 No excluded sales for the selected reporting period.
               </td>
             </tr>
           : invalidSales
           .sort((a,b) => a.saleID > b.saleID ? 1 : -1)
           .map(
             invalidSale => (
               <tr key={invalidSale.saleID}>
                 <td>
                   {/* <a href={`https://us.merchantos.com/API/Account/${accountInfo.accountID}/DisplayTemplate/Sale/${invalidSale.saleID}.html?template=SaleReceipt`}> */}
                     {invalidSale.saleID}
                   {/* </a> */}
                 </td>
                 <td>{invalidSale.invalidReason}</td>
               </tr>
             )
           )}
        </tbody>
      </table>
    </div>
  );
}
