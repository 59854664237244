import React from "react";
import Dinero from 'dinero.js';
import {DateTime} from "luxon";
import IncomeReport from "./IncomeReport.jsx";
import ErrorMessage from "./ErrorMessage.jsx";

export default function DayDataProcessor({
  dayData,
  reportGenerated,
  reportPeriodStart,
  reportPeriodEnd
}) {
  console.debug("DayDataProcessor got dayData:",dayData);
  try {
    const processedSales = dayData.map(sale => processSingleSale(sale));
    const invalidSales = processedSales.filter(sale => sale.invalidReason);
    const validSales = processedSales.filter(sale => !sale.invalidReason);
    const validSalesDetailLines = validSales.map(
      validSale => validSale.detailReportItems).reduce((a,b) => a.concat(b),[]);
    return <IncomeReport
             invalidSales={invalidSales}
             validSalesDetailLines={validSalesDetailLines}
             reportGenerated={reportGenerated}
             reportPeriodStart={reportPeriodStart}
             reportPeriodEnd={reportPeriodEnd}/>;
  } catch (e) {
    return <ErrorMessage message={e}/>;
  }
}

function paymentTypeToTenderType(paymentType) {
  // Consumes a PaymentType object produced by the Lightspeed API and
  // returns a string describing the tender type (one of "cash",
  // "check", "card", "ecom") that has meaning only inside this
  // report, or throws an error indicating that the payment did not
  // map to a tender type.
  switch (paymentType.type) {
  case "cash":
    return "cash";
  case "user defined":
    if (paymentType.code === "Check") {
      return "check";
    }
    else {
      throw new Error("Unsupported payment type.");
    }
  case "credit card":
    if (paymentType.code === "Credit Card" ||
        paymentType.code === "Debit Card") {
      return "card";
    }
    else {
      throw new Error("Unsupported payment type.");
    }
  case "ecom":
    return "ecom";
  default:
    throw new Error("Unsupported payment type.");
  }
}

function processSingleSale(saleAPIObject) {
  let saleID;
  try {
    saleID = parseInt(saleAPIObject.saleID);
  } catch(e) {
    console.warn(
      "Failed to extract sale ID from saleAPIObject:",
      saleAPIObject);
    return {
      detailReportItems: [],
      saleID: -1,
      invalidReason: "Sale contained no valid sale number."
    };
  }
  try {
    if (!saleAPIObject.hasOwnProperty("SalePayments")) {
      // This can happen when ringing up free Saturday museum admission
      // for which payment is not attempted.
      return {
        detailReportItems: [],
        saleID: saleID,
        invalidReason: "Sale has no attempted payments."
      };
    }
  } catch(e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while checking the sale for associated payments."
    };
  }
  let attemptedSalePayments;
  try {
    attemptedSalePayments = (
      Array.isArray(saleAPIObject.SalePayments.SalePayment)
        ? saleAPIObject.SalePayments.SalePayment
        : [saleAPIObject.SalePayments.SalePayment]);
  } catch(e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while extracting attempted sale payments."
    };
  }
  let successfulSalePayments;
  try {
    successfulSalePayments = attemptedSalePayments.filter(
      attemptedSalePayment => {
        let tenderType;
        try {
          tenderType = paymentTypeToTenderType(attemptedSalePayment.PaymentType);
        } catch (e) {
          console.warn(
            `Sale ${saleID} contains the following attempted payment with an unsupported type:`,
            attemptedSalePayment);
          throw new Error("Sale had an associated payment, but its type was not cash, check, card, or ecom.");
        }
        if (tenderType !== "card") {
          return true;
        }
        switch (attemptedSalePayment.m.ReceiptData.status) {
        case "approved":
          return true;
        case "cancelled":
        case "declined":
        case "error":
          return false;
        default:
          throw new Error("Sale involved a card transaction, but the status was not approved, cancelled, declined, or error.");
        }
      });
  } catch(error) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: error.message
    };
  }
  try {
    if (successfulSalePayments.length == 0) {
      return {
        detailReportItems: [],
        saleID: saleID,
        invalidReason: "Sale has no successful payments."
      };
    }
  } catch(e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while checking for successful payments."
    };
  }
  
  // Simple payments are objects containing only report-relevant data
  // from the API object.
  let simplePayments;
  try {
    simplePayments = successfulSalePayments.map(
      successfulSalePayment => {
        const paymentTypeCode = successfulSalePayment.PaymentType.code;
        const tenderType = paymentTypeToTenderType(successfulSalePayment.PaymentType);
        const amount = parseAmount(successfulSalePayment.amount);
        const cardBrand = (
          tenderType === "card"
            ? successfulSalePayment.m.ReceiptData.card_brand.split(" ")[0]
            : null);
        const cardLastFour = (
          tenderType === "card"
            ? successfulSalePayment.m.ReceiptData.card_number.substring(
              successfulSalePayment.m.ReceiptData.card_number.length-4)
            : null);
        return {tenderType, amount, cardBrand, cardLastFour};
      });
  } catch (e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while extracting payment information from sale."
    };
  }
  try {
    if ((new Set(simplePayments.map(payment => payment.tenderType))).size > 1) {
      return {detailReportItems: [],
              saleID: saleID,
              invalidReason: "Sale mixes tender types."};
    }
  } catch (e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while checking whether the sale mixes tender types."
    };
  }
  // Represents how the payment will be displayed in detail report lines.
  let aggregatePayment;
  try {
    aggregatePayment = (
      simplePayments.length === 1
        ? simplePayments[0]
        : {
          tenderType: simplePayments[0].tenderType,
          amount: simplePayments.map(simplePayment => simplePayment.amount)
            .reduce((a,b) => a.add(b)),
          cardBrand: (simplePayments[0].cardBrand === null ? null
                      : simplePayments.map(simplePayment => simplePayment.cardBrand)
                      .join(", ")),
          cardLastFour: (simplePayments[0].cardLastFour === null ? null
                         : simplePayments.map(simplePayment => simplePayment.cardLastFour)
                         .join(", "))
        });
  } catch(e) {
    return {detailReportItems: [],
            saleID: saleID,
            invalidReason: "Encountered an error while aggregating multiple payments of the same type."};
  }
  try {
    if (!saleAPIObject.hasOwnProperty("SaleLines")) {
      return {detailReportItems: [],
              saleID: saleID,
              invalidReason: "Sale has no line items."};
    }
    if (!saleAPIObject.SaleLines.hasOwnProperty("SaleLine")) {
      return {detailReportItems: [],
              saleID: saleID,
              invalidReason: "Sale has no line items."};
    }
  } catch(e) {
    return {detailReportItems: [],
            saleID: saleID,
            invalidReason: "Encountered an error while checking whether the sale has associated line items."};
  }

  // Simple sale lines are objects containing only report-relevant
  // data from the API object.
  let simpleSaleLines = [];
  try {
    const APISaleLines = (
      Array.isArray(saleAPIObject.SaleLines.SaleLine)
        ? saleAPIObject.SaleLines.SaleLine
        : [saleAPIObject.SaleLines.SaleLine]);
    for (const APISaleLine of APISaleLines) {
      let categoryFullPathName;
      try {
        categoryFullPathName = APISaleLine.Item.Category.fullPathName;
      } catch(e) {
        return {
          detailReportItems: [],
          saleID: saleID,
          invalidReason:
          "Category full path name missing from line item."
        };
      }
      const topLevelCategory = categoryFullPathName.split("/")[0];
      simpleSaleLines.push({
        topLevelCategory,
        lineSubtotal: parseAmount(APISaleLine.calcSubtotal),
        lineTax1: parseAmount(APISaleLine.calcTax1),
        lineTax2: parseAmount(APISaleLine.calcTax2),
        lineTotal: parseAmount(APISaleLine.calcTotal),
        // included for rendering react key in detail line item reports:
        saleLineID: APISaleLine.saleLineID
      });
    }
  } catch(e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while extracting line items from sale."
    };
  }
  try {
    if (!aggregatePayment.amount.equalsTo(
      simpleSaleLines.map(simpleSaleLine => simpleSaleLine.lineTotal)
        .reduce((a,b) => a.add(b))
    )) {
      return {detailReportItems: [],
              saleID: saleID,
              invalidReason:
              "Sum of all sale line items does not equal sum of all sale payments."};
    }
  } catch(e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while checking whether the sum of all sales equals the sum of all payments."
    };
  }
  let employeeLast;
  try {
    employeeLast = saleAPIObject.Employee.lastName;
  } catch(e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while determining the associated cashier's last name."
    };
  }
  let saleDateTime;
  try {
    saleDateTime = DateTime.fromISO(saleAPIObject.timeStamp);
  }
  catch (e) {
    return {
      detailReportItems: [],
      saleID: saleID,
      invalidReason: "Encountered an error while determining the date and time of the sale."
    };
  }
  return {detailReportItems: simpleSaleLines.map(simpleSaleLine => ({
    saleID,
    saleLineID: simpleSaleLine.saleLineID,
    saleDateTime,
    employeeLast,
    topLevelCategory: simpleSaleLine.topLevelCategory,
    subtotal: simpleSaleLine.lineSubtotal,
    tax1: simpleSaleLine.lineTax1,
    tax2: simpleSaleLine.lineTax2,
    total: simpleSaleLine.lineTotal,
    tenderType: aggregatePayment.tenderType,
    cardBrand: aggregatePayment.cardBrand,
    cardLastFour: aggregatePayment.cardLastFour
  })),
          saleID: saleID,
          invalidReason: null};
}

function parseAmount(amountString) {
  const isNegative = amountString[0] === "-";
  const numericPortion = (
    isNegative
      ? amountString.substring(1)
      : amountString);
  const dollarPortionCount = numericPortion.includes(".")
        ? parseInt(numericPortion.split(".")[0])
        : parseInt(numericPortion);

  const centsPortionString = numericPortion.includes(".")
        ? numericPortion.split(".")[1]
        : null;
  const centsPortionCount = centsPortionString
        ? (centsPortionString.length === 1
           ? parseInt(centsPortionString) * 10
           : parseInt(centsPortionString))
        : 0;

  const pennyCount = dollarPortionCount * 100 + centsPortionCount;

  const minorCurrencyUnits = (
    isNegative
      ? -1 * pennyCount
      : pennyCount);
  return Dinero({amount: minorCurrencyUnits, currency: "USD"});
}
