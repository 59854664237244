import React, {useState, useEffect, useReducer} from "react";
import {DateTime} from "luxon";
import DayDataProcessor from "./DayDataProcessor.jsx";
import ErrorMessage from "./ErrorMessage.jsx";

function salesReducer(state, action) {
  console.debug("salesReducer received:",action);
  switch (action.type) {
  case 'appendSales':
    return {...state,
            retrievedSales:
            state.retrievedSales.concat(action.newSales)};
  case 'setTotalSales':
    return {...state,
            totalSales: action.totalSales};
  case 'reset':
    return {totalSales: null,
            retrievedSales: []};
  default:
    throw new Error("Invalid dispatch action");
  }
}

export default function DayDataFetcher({accountID}) {
  const [reportDate, setReportDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sales, salesDispatch] = useReducer(salesReducer,
                                            {totalSales: null,
                                             retrievedSales: []});
  const reportGenerated = DateTime.now();
  const beginningOfDay = DateTime.fromFormat(
    reportDate,
    "yyyy-MM-dd",
    {zone: "America/Denver"});
  const endOfDay = beginningOfDay.plus({days: 1});
  console.debug("Beginning of day:",
                beginningOfDay.toISO(),
                "End of day:",
                endOfDay.toISO());
  
  useEffect(() => {
    salesDispatch({type: 'reset'});
    if (reportDate === "") {
      return () => {};
    }
    const controller = new AbortController();
    const signal = controller.signal;
    setIsLoading(true);
    (async () => {
      let lastSaleID = 0;
      let totalSales = null; // must be specified here as we're in a closure
      // Note: 2021-07-02 has 158 sales and requires pagination to display
      while (true) {
        const fetchURL = `/api/v1/account/${accountID}/display_template_sale` +
              `?report_period_start=${encodeURIComponent(beginningOfDay.toISO())}` +
              `&report_period_end=${encodeURIComponent(endOfDay.toISO())}` +
              `&sale_ids_greater_than=${encodeURIComponent(lastSaleID)}`;
        // Need error handling here, plus special wait code for 429
        // responses from Lightspeed.
        let response;
        try {
          response = await fetch(fetchURL, {signal});
        }
        catch (fetchError) {
          if (fetchError.name === "AbortError") {
            return;
          } else {
            setError(fetchError);
            return;
          }
        }
        if (response.status === 429) {
          setError("Lightspeed Retail server reports that it is overloaded. Please wait 15 minutes and try again.");
          return;
        }
        if (response.status === 401) {
          setError("Your access to Lightspeed Retail has expired. Please log out and log back in.");
          return;
        }
        try {
          const response_data = await response.json();
          const responseCount = parseInt(response_data["@attributes"]["count"]);
          const responseLimit = parseInt(response_data["@attributes"]["limit"]);
          if (responseCount === 0) {
            break;
          }
          if (totalSales === null) {
            totalSales = responseCount;
            salesDispatch({type: 'setTotalSales',
                           totalSales: responseCount});
          }
          const newSales = (Array.isArray(response_data.Sale)
                            ? response_data.Sale
                            : [response_data.Sale]);
          salesDispatch({type: 'appendSales',
                         newSales});
          if (responseCount <= responseLimit) {
            break;
          }
          lastSaleID = newSales.map(x => parseInt(x.saleID))
            .reduce((a,b) => Math.max(a,b));
        } catch (e) {
          setError(e);
          return;
        }
      }
      setIsLoading(false);
    })();
    
    return () => {
      controller.abort();
    };
  },[reportDate, accountID]);

  if (error) {
    return <ErrorMessage message={error}/>;
  }
  
  return (
    <>
      <div className="mb-3">
        <label htmlFor="reportDate" className="form-label">
          Choose Report Date
        </label>
        <input
          className="form-control"
          type="date"
          id="reportDate"
          value={reportDate}
          onChange={(e) => setReportDate(e.target.value)}/>
      </div>
      <div>
        {reportDate === "" ? null
         :(isLoading ? (sales.totalSales === null
                        ?<div className="text-center">
                           <div className="spinner-border my-4" role="status">
                             <span className="visually-hidden">Loading...</span>
                           </div>
                         </div>
                        : <div className="progress my-4">
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              aria-valuenow={sales.retrievedSales.length * 100 / sales.totalSales}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{width: (sales.retrievedSales.length * 100 / sales.totalSales) + "%"}}
                            >
                              Loaded {sales.retrievedSales.length} of {sales.totalSales} sales...
                            </div>
                          </div>)
         : <DayDataProcessor
               dayData={sales.retrievedSales}
               reportGenerated={reportGenerated}
               reportPeriodStart={beginningOfDay}
               reportPeriodEnd={endOfDay}/>
          )}
      </div>
    </>
  );
}
