import React from "react";
import LoginController from "./LoginController.jsx";
import DayDataFetcher from "./DayDataFetcher.jsx";

function App() {
  return (
    <React.StrictMode>
      <div className="container-lg">
        <LoginController/>
      </div>
    </React.StrictMode>
  );
}

export default App;
