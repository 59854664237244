import React from "react";

export default function ErrorMessage({message}) {
  console.error("Presenting error message:", message);
  return (
    <div className="m-4">
      <p className="text-danger display-4">Error</p>
      <p>The application encountered an error. Troubleshooting steps:</p>
      <ol>
        <li>Refresh the page and try again.</li>
        <li>Log out, log back in, and try again.</li>
        <li>Wait 15 minutes and try again.</li>
        <li>Contact technical support. There may be a fee for this service.</li>
      </ol>
      <p className="text-muted">Error message: {`${message}`}</p>
    </div>
  );
}
